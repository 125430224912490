import { baseMutations, baseActions } from '../funcs'

export default {

  namespaced: true,
  state: {
    // Info
    items: [],
    title: 'usuarios',
    name: 'Usuarios',
    loading: true,
    selected: null, // for drag drop
    path: '/usuarios',

    // API Get index
    get: {
      urn: 'user/index?group_key="Admin"'
      // response: 'users'
    },
    show: 'user/show/',
    update: 'auth/edit',
    group: 'Admin',
    request: 'auth/create',
    delete: 'user/delete/',
    hasExtraField: true,
    formFields: [
      { key: 'name', label: 'Nome', type: 'text', col: '5', required: true },
      { key: 'email', label: 'Email', type: 'email', col: '4' },
      {
        key: 'telephone',
        label: 'Telefone',
        type: 'text',
        col: '3',
        mask: [
          '(###) ##-##-##',
          '(##) #####-####',
          '(##) ####-####',
          '###-###-####'
        ],
        required: true
      },
      {
        key: 'document',
        label: 'Documento',
        type: 'text',
        col: '3',
        mask: ['###.###.###-##', '##.###.###/####-##'],
        required: true
      }
    ]

  },
  mutations: Object.assign({}, baseMutations, {

    selected (state, payload) {
      state.selected = payload
    }

  }),
  actions: Object.assign({}, baseActions, {

    selected (context, payload) {
      context.commit('selected', payload)
    }

  })
}
