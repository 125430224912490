import Vue from 'vue'
import Vuex from 'vuex'

import { api } from '@/services'

import Sistemas from './modules/erps'
import Usuarios from './modules/usuarios'
import Rotas from './modules/rotas'
import Clientes from './modules/clientes'
import Modulos from './modules/modulos'
import Empresas from './modules/empresas'
import Configuracoes from './modules/configuracoes'
import Extrafield from './modules/extrafields'
import Integracoes from './modules/integracoes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    dashboard: ['rotas', 'erps', 'usuarios', 'clientes', 'empresas', 'configuracoes'],
    menuMobile: false,
    // states para as telas:
    selected: null,
    // configure on table
    groupObj: JSON.parse(localStorage.groupObj || '{}'),
    groupObjId: localStorage.groupObjId || '',
    // ranges
    ranges: null,
    groupSelection: JSON.stringify(localStorage.groupSelection || ''),
    // configurar sistemas
    values: null,
    objValues: null,
    selectedValues: JSON.parse(localStorage.selectedValues || '{}'),
    erp: null,
    enterprise: null,
    dictionary: null,
    newRoute: null,
    // modules
    moduleToCopy: JSON.parse(localStorage.moduleToCopy || '{}'),
    editModule: JSON.parse(localStorage.editModule || '{}'),
    preloadToSave: null,
    moduleItems: JSON.parse(localStorage.moduleItems || '{}'),
    allModuleItems: null,
    integracaoId: null,
    updateIntegracao: null
  },
  getters: {
    userName: state => state.user.name === undefined ? '' : state.user.name,
    isLogged: state => Object.keys(state.user).length !== 0 && state.token !== undefined,
    getGroupObj: state => state.groupObj,
    getGroupObjId: state => state.groupObjId,
    moduleToCopy: state => state.moduleToCopy,
    getRanges: state => state.ranges,
    groupSelection: state => state.groupSelection,
    values: state => state.values,
    objValues: state => state.objValues,
    selectedValues: state => state.selectedValues,
    erp: state => state.erp,
    newRoute: state => state.newRoute,
    enterprise: state => state.enterprise,
    dictionary: state => state.dictionary,
    getEditedModule: state => state.editModule,
    getPreloadToSave: state => state.preloadToSave,
    getModuleItems: state => state.moduleItems,
    getAllModuleItems: state => state.allModuleItems,
    updateIntegracao: state => state.updateIntegracao,
    getIntegracaoId: state => state.integracaoId
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user
      state.token = payload.token
    },
    logout: state => {
      state.user = {}
      state.token = undefined
    },
    getGroupObj: (state, payload) => {
      state.groupObj = payload
    },
    getGroupObjId: (state, payload) => {
      state.groupObjId = payload
    },
    moduleToCopy: (state, payload) => {
      state.moduleToCopy = payload
    },
    getRanges: (state, payload) => {
      state.ranges = payload
    },
    getValues: (state, payload) => {
      state.values = payload
    },
    getObjValues: (state, payload) => {
      state.objValues = payload
    },
    groupSelection: (state, payload) => {
      state.groupSelection = payload
    },
    selectedValues: (state, payload) => {
      state.selectedValues = payload
    },
    erp: (state, payload) => {
      state.erp = payload
    },
    newRoute: (state, payload) => {
      state.newRoute = payload
    },
    enterprise: (state, payload) => {
      state.enterprise = payload
    },
    dictionary: (state, payload) => {
      state.dictionary = payload
    },
    editModule: (state, payload) => {
      state.editModule = payload
    },
    preloadToSave: (state, payload) => {
      state.preloadToSave = payload
    },
    toggleMenuMobile: state => {
      state.menuMobile = !state.menuMobile
    },
    moduleItems: (state, payload) => {
      state.moduleItems = payload
    },
    getAllModuleItems: (state, payload) => {
      state.allModuleItems = payload
    },
    updateIntegracao: (state, payload) => {
      state.updateIntegracao = payload
    },
    getIntegracaoId: (state, payload) => {
      state.integracaoId = payload
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user)
      localStorage.token = payload.token
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`
      context.commit('login', payload)
    },
    logout: context => {
      localStorage.clear()
      delete api.defaults.headers.common.Authorization
      context.commit('logout')
    },
    getGroupObj: (context, payload) => {
      localStorage.groupObj = JSON.stringify(payload)
      context.commit('getGroupObj', payload)
    },
    getGroupObjId: (context, payload) => {
      localStorage.groupObjId = payload
      context.commit('getGroupObjId', payload)
    },
    moduleToCopy: (context, payload) => {
      localStorage.moduleToCopy = payload
      context.commit('moduleToCopy', payload)
    },
    getRanges: (context, payload) => {
      context.commit('getRanges', payload)
    },
    getValues: (context, payload) => {
      context.commit('values', payload)
    },
    getObjValues: (context, payload) => {
      context.commit('objValues', payload)
    },
    groupSelection: (context, payload) => {
      localStorage.groupSelection = payload
      context.commit('groupSelection', payload)
    },
    erp: (context, payload) => {
      context.commit('erp', payload)
    },
    newRoute: (context, payload) => {
      context.commit('newRoute', payload)
    },
    enterprise: (context, payload) => {
      context.commit('enterprise', payload)
    },
    dictionary: (context, payload) => {
      context.commit('dictionary', payload)
    },
    selectedValues: (context, payload) => {
      localStorage.selectedValues = JSON.stringify(payload)
      context.commit('selectedValues', payload)
    },
    editModule: (context, payload) => {
      localStorage.editModule = JSON.stringify(payload)
      context.commit('editModule', payload)
    },
    preloadToSave: (context, payload) => {
      context.commit('preloadToSave', payload)
    },
    menuMobile: context => {
      context.commit('toggleMenuMobile')
    },
    moduleItems: (context, payload) => {
      localStorage.moduleItems = JSON.stringify(payload)
      context.commit('moduleItems', payload)
    },
    getAllModuleItems: (context, payload) => {
      context.commit('getAllModuleItems', payload)
    },
    updateIntegracao: (context, payload) => {
      context.commit('updateIntegracao', payload)
    },
    getIntegracaoId: (context, payload) => {
      context.commit('getIntegracaoId', payload)
    }
  },
  modules: {
    usuarios: Usuarios,
    sistemas: Sistemas,
    rotas: Rotas,
    clientes: Clientes,
    modulos: Modulos,
    empresas: Empresas,
    configuracoes: Configuracoes,
    extrafield: Extrafield,
    integracoes: Integracoes
  }
})
